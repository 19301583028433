<template>
    <v-select :dense="dense" :hide-details="hideDetails" clearable :outlined="outlined" :label="label" :disabled="carregando || disabled" :items="items" v-model="id_registro" />
</template>

<script>

export default {
    name: "SelectSimNao",
    props : ['dense', 'hideDetails', 'outlined', 'label', 'disabled', 'value', 'clearable'],
    data() {
        return {
            id_registro : null,
            items : [
                {value : true, text : 'Sim'},
                {value : false, text : 'Não'},
            ],
            carregando : false
        }
    },
    watch : {
        'id_registro' : function(v) {
            this.$emit('input', v)
        },
        'value' : function(v) {
            this.id_registro = v
        }
    },
    created() {
        this.id_registro = this.value
    }
}
</script>

<style scoped>

</style>