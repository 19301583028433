<template>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                    <v-spacer/>
                    <v-chip small color="green" dark v-if="chamado.dh_conclusao"><v-icon left small>mdi-check-circle</v-icon> Concluído</v-chip>
                    <v-chip small color="orange" dark v-else><v-icon left small>mdi-close-circle</v-icon> Pendente</v-chip>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" class="py-0"><p class="text--primary mb-2"><strong>Categoria:</strong> {{chamado.categoria.categoria}}</p></v-col>
                        <v-col cols="12" sm="6" class="py-0"><p class="text--primary mb-2"><strong>Assunto:</strong> {{chamado.chamado}}</p></v-col>
                        <v-col cols="12" sm="6" class="py-0"><p class="text--primary mb-2"><strong>ID Chamado:</strong> {{id_chamado}}</p></v-col>
                        <v-col cols="12" sm="6" class="py-0"><p class="text--primary mb-2"><strong>Incluído em:</strong> {{dataPt(chamado.dh_cadastro)}} <strong>por</strong> {{chamado.usuario_cadastro.usuario}}</p></v-col>
                        <v-col cols="12" sm="6" class="py-0" v-if="chamado.usuario_conclusao"><p class="text--primary mb-2"><strong>Concluído por:</strong> {{chamado.usuario_conclusao.usuario}}</p></v-col>
                        <v-col cols="12" sm="6" class="py-0"><p class="text--primary mb-2"><strong>Franquia:</strong> {{chamado.franquia.franquia}}</p></v-col>
                        <v-col cols="12" sm="6" class="py-0"><p class="text--primary mb-2"><strong>Descrição:</strong> <span style="white-space: pre;">{{chamado.descricao}}</span></p></v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" v-for="(r, i) in chamado.registros" :key="i">
            <v-card>
                <v-card-title>{{r.usuario_cadastro.usuario}}</v-card-title>
                <v-card-subtitle>{{dataPt(r.dh_cadastro)}}</v-card-subtitle>
                <v-divider/>
                <v-card-text>
                    <v-row>
                        <v-col cols="12"><p class="text--primary mb-2" style="white-space: pre;">{{r.registro}}</p></v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Novo registro</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12"><SelectSimNao hide-details label="* Concluído:" v-model="registro.concluido" /></v-col>
                            <v-col cols="12"><v-textarea hide-details label="* Mensagem:" v-model="registro.registro" /></v-col>
                        </v-row>
                    </v-form>
                    <v-alert v-if="erro" type="error" class="mt-5"><div v-html="erro"></div></v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="primary" @click="salvar">Incluir</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>

import {mapState} from "vuex"
import axios from "axios"
import SelectSimNao from "@/Views/Estrutura/Form/SelectSimNao.vue"

export default {
    name: "ChamadoGerenciar",
    components: {SelectSimNao},
    props : ['id_chamado'],
    data() {
        return {
            chamado : {},
            registro : {},
            erro : null
        }
    },
    computed : {
        ...mapState(['apiUrl'])
    },
    methods : {
        get() {
            return axios.post(`${this.apiUrl}chamado/get`, {id_chamado : parseInt(this.id_chamado)}).then( (res) => {
                this.chamado = res.data
            })
        },
        salvar() {
            axios.post(`${this.apiUrl}chamado/registro/novo`, {...this.registro, id_chamado : parseInt(this.id_chamado)}).then( () => {
                this.erro = null
                this.registro = {}
                this.get()
            }).catch(e => {
                this.erro = this.erroFormatar(e)
            })
        }
    },
    async activated() {
        await this.get()
    }
}
</script>

<style scoped>

</style>
